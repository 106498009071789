import React from "react"
import { Link } from "gatsby"

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    if (location.pathname === rootPath) {
      header = (
        <h1
          style={{
            fontSize: "3.95285rem",
            lineHeight: "4.375rem",
            marginBottom: "2.625rem",
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: "none",
              textDecoration: "none",
              color: "inherit",
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h1>
      )
    } else {
      header = (
        <h3
          style={{
            fontFamily: "Montserrat, sans-serif",
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: "none",
              textDecoration: "none",
              color: "inherit",
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h3>
      )
    }
    return (
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: "50rem",
          padding: "3rem 1.25rem",
        }}

        className="site"
      >
        <header>{header}</header>
        <main className="site_content">{children}</main>
        <hr
            style={{
              marginBottom: "1.75rem",
              marginTop: "3rem",
            }}
          />
        <footer className="main-footer">
          <p className="ma0">Rachel P. © {new Date().getFullYear()}, codé avec ❤ &amp;
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a></p>
          <p className="ma0">Blog in progress <span className="waiting">...</span></p>
        </footer>
      </div>
    )
  }
}

export default Layout
